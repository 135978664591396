<template>
	<div class="card">
		<Card>
			<template #title>
				<h4>{{$t('Información Básica')}}</h4>
			</template>
			<template #content>
                <div class="p-grid">
                    <div class="p-col-6">
						{{$t('Número ID')}}: <strong>{{inf.code}}</strong>			
					</div>
                    <div class="p-col-6">
						{{$t('Localidad')}}: <strong>{{detalle.localidadn}}</strong>			
					</div>
					<div class="p-col-12">
						{{$t('Nombre Completo')}}: <strong>{{inf.nombre}}</strong>			
					</div>
                    <div class="p-col-12">
						{{$t('Correo')}}: <strong>{{inf.pcorreo}}</strong>			
					</div>
                    <div class="p-col-6">
						{{$t('Teléfono móvil')}}: <strong>{{detalle.celular}}</strong>			
					</div>
					<div class="p-col-6">
						{{$t('Teléfono privado')}}: <strong>{{detalle.tlflocal}}</strong>			
					</div>
                </div>
			</template>
		</Card>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
        props: {
            detall: {
                type: Object,
                default: null,
            },
            datos: {
                type: Object,
                default: null,
            },
        },
        data() {
			return {
				i18n: null,
                inf: this.datos,
                detalle: this.detall,
			}
		},
		created() {
			this.i18n = useI18nPlugin();
		},
        methods: {

        }
	}
</script>
